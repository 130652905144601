import React from 'react';
// import classnames from 'classnames';

import { section, list, titleStyle } from './styles.module.scss';
import PastWebinar from '../components/PastWebinar';

const PastWebinars = ({ data, title }) => {
  return (
    <section className={section}>
      <div className="scr-wrap">
        <p className={titleStyle}>{title}</p>

        <ul className={list}>
          {data?.length > 0 ? (
            data.map((webinar) => {
              return (
                <li key={webinar.title}>
                  <PastWebinar data={webinar} />
                </li>
              );
            })
          ) : (
            <></>
          )}
        </ul>
      </div>
    </section>
  );
};

export default PastWebinars;
