export const WEBINAR_INDEX = {
  en: {
    seo: {
      title: 'What’s happening at Screver? | Events & Webinars | Screver',
      metaDesc:
        'What’s happening at Screver? Find out what our team planned for the upcoming months - webinars or exclusive events. You can find on-demand webinars and events',
      opengraphUrl: 'https://screver.com/events/',
    },
    hero: {
      title: 'What’s happening at Screver?',
      subtitle:
        'Find out what our team planned for the upcoming months - webinars or exclusive events.',
    },
    onDemandTitle: 'On-demand webinars and events',
    langSwitcher: 'Event language',
  },
  de: {
    seo: {
      title: 'Was ist bei Screver los? | Events & Webinars | Screver',
      metaDesc:
        'Erfahren Sie, was unser Team in den kommenden Monaten geplant hat - Webinare oder exklusive Veranstaltungen. Auch Webinare und Veranstaltungen auf Abruf',
      opengraphUrl: 'https://screver.com/events/',
    },
    hero: {
      title: 'Was ist bei Screver los?',
      subtitle:
        'Erfahren Sie, was unser Team in den kommenden Monaten geplant hat - Webinare oder exklusive Veranstaltungen.',
    },
    onDemandTitle: 'Webinare und Veranstaltungen auf Abruf',
    langSwitcher: 'Sprache der Veranstaltung',
  },
};
