import { useStaticQuery, graphql } from 'gatsby';

const allWebinarsOnDemand = graphql`
  query {
    allWpWebinarOnDemand {
      nodes {
        id
        slug
        uri
        title
        language {
          code
          name
          locale
          slug
        }
        webinarOnDemand {
          webinarOnDemandVideo {
            webinarOnDemandFreeVideoAccess
          }
          webinarOnDemandHero {
            webinarOnDemandHeroTags {
              webinarOnDemandHeroTagItem
            }
            webinarOnDemandHeroDate
            webinarOnDemandHeroBanner {
              sourceUrl
              srcSet
            }
          }
        }
      }
    }
  }
`;

const useAllWebinarsOnDemand = () => {
  const {
    allWpWebinarOnDemand: { nodes },
  } = useStaticQuery(allWebinarsOnDemand);

  const webinars = nodes.filter(
    (item) =>
      !item.webinarOnDemand.webinarOnDemandVideo.webinarOnDemandFreeVideoAccess
  );

  return webinars;
};

export default useAllWebinarsOnDemand;
