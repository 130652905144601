import { useStaticQuery, graphql } from 'gatsby';

const allWebinars = graphql`
  query {
    allWpWebinar {
      nodes {
        id
        slug
        uri
        title
        language {
          code
          name
          locale
          slug
        }
        webinar {
          webinarHero {
            webinarHeroTags {
              webinarHeroTagItem
            }
            webinarHeroDescription
            webinarPreviewTimeNote
            webinarHeroTimeStart
            webinarHeroDate
            webinarHeroBanner {
              sourceUrl
            }
          }
          webinarHideOnIndex
        }
      }
    }
  }
`;

const useAllWebinars = () => {
  const {
    allWpWebinar: { nodes },
  } = useStaticQuery(allWebinars);

  return nodes;
};

export default useAllWebinars;
