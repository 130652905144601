import React, { useMemo } from 'react';

import Layout from 'sections/Layout';
import Hero from 'sections/common/Hero';
import NextWebinars from 'sections/WebinarIndex/NextWebinars';
import PastWebinars from 'sections/WebinarIndex/PastWebinars';
// import LocaleSelect from 'components/LocaleSelect';
import ScreverInActionSec from 'sections/common/ScreverInAction';

import useAllWebinars from 'hooks/graphql/useAllWebinars';
import useAllWebinarsOnDemand from 'hooks/graphql/useAllWebinarsOnDemand';
import useCurrentLocale from 'hooks/useCurrentLocale';

import { WEBINAR_INDEX } from 'mocks/webinar-index';
import { EVENTS } from 'constants/events';

const lang = 'en';

const heroStyles = {
  color: '#605DF0',
  background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='986' height='310' fill='none' viewBox='0 0 986 310'%3E%3Cg clip-path='url(%23clip0_5183_2541)'%3E%3Cpath stroke='%23E1FF67' stroke-linecap='round' stroke-linejoin='round' stroke-width='5' d='M359.789-448.186c-150.441 9.188-248.602 123.672-293.19 238.159-179.88 461.871 252.267 738.744 473.142 249.721 23.046-51.025 35.036-115.518 4.316-188.197-6.992-16.543-20.808-42.321-42.278-45.406-24.461-3.515-42.241 15.758-55.288 26.263-39.945 32.163-99.85 74.778-83.186 144.616 14.903 62.462 76.705 130.353 140.463 172.685 46.265 30.717 77.544 31.949 105.42 12.435 31.321-21.926 61.365-51.316 74.8-88.106 2.089-5.723 8.152-22.842-7.972-43.573-41.463-48.834-8.021 65.732 23.763 91.954 43.264 35.693 116.203 73.988 159.664 69.506 26.387-2.721 48.619-38.957 31.148-70.418-17.196-30.966-63.736-2.382-71.698 6.273-7.365 8.008-1.569 10.092 10.753 11.793 38.486 5.31 71.925 13.319 110.104 45.055 16.798 13.964 50.365 47.056 36.102 61.094-8.493 8.359-13.213-8.147-9.613-13.587 15.986-24.155 124.351 34.472 115.011-23.71'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_5183_2541'%3E%3Cpath fill='%23fff' d='M0 0h1114v310H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E")`,
};

const WebinarIndex = () => {
  const allWebinars = useAllWebinars();
  const allWebinarsOnDemand = useAllWebinarsOnDemand();
  const { currentLocale } = useCurrentLocale();

  const webinars = useMemo(() => {
    const _webinars = allWebinars.filter(
      (item) =>
        item.language.slug === currentLocale && !item.webinar.webinarHideOnIndex
    );

    return _webinars;
  }, [allWebinars, currentLocale]);

  const webinarsonOnDemand = useMemo(() => {
    const arrayOfWebinars = [...allWebinarsOnDemand, ...EVENTS];

    const _webinarsonOnDemand = arrayOfWebinars.filter(
      (item) => item.language.slug === currentLocale
    );

    return _webinarsonOnDemand;
  }, [allWebinarsOnDemand, currentLocale]);

  const heroData = {
    title: WEBINAR_INDEX[currentLocale].hero.title,
    subtitle: WEBINAR_INDEX[currentLocale].hero.subtitle,
    langSwitcherTitle: WEBINAR_INDEX[currentLocale].hero.langSwitcher,
  };

  return (
    <Layout
      seo={WEBINAR_INDEX[lang].seo}
      headerDarkColor={true}
      headerWhiteBg={true}
      requestButtonBlue={true}
    >
      {(commonData) => (
        <>
          <Hero
            data={heroData}
            color={heroStyles.color}
            background={heroStyles.background}
          />
          {/* <LocaleSelect title={WEBINAR_INDEX[currentLocale].langSwitcher} /> */}
          <NextWebinars data={webinars} />

          {webinarsonOnDemand?.length > 0 ? (
            <PastWebinars
              title={WEBINAR_INDEX[currentLocale].onDemandTitle}
              data={webinarsonOnDemand}
            />
          ) : (
            <></>
          )}

          <ScreverInActionSec />
        </>
      )}
    </Layout>
  );
};

export default WebinarIndex;
