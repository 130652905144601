import React from 'react';

import { section, list } from './styles.module.scss';
import NextWebinar from '../components/NextWebinar';

const NextWebinars = ({ data }) => {
  return (
    <section className={section}>
      <div className="scr-wrap">
        <ul className={list}>
          {data?.length > 0 ? (
            data.map((webinar) => {
              return (
                <li key={webinar.title}>
                  <NextWebinar data={webinar} />
                </li>
              );
            })
          ) : (
            <></>
          )}
        </ul>
      </div>
    </section>
  );
};

export default NextWebinars;
