import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

import { box, image, about, dateStyle, titleStyle } from './styles.module.scss';

const PastWebinar = ({ data }) => {
  const link = data.uri;
  const banner =
    data.webinarOnDemand.webinarOnDemandHero.webinarOnDemandHeroBanner
      .sourceUrl;
  const bannerSrcSet =
    data.webinarOnDemand.webinarOnDemandHero.webinarOnDemandHeroBanner.srcSet;
  const tag =
    data.webinarOnDemand.webinarOnDemandHero.webinarOnDemandHeroTags[0]
      .webinarOnDemandHeroTagItem;
  const title = data.title;
  const date = data.webinarOnDemand.webinarOnDemandHero.webinarOnDemandHeroDate;

  return (
    <Link to={link} className={box}>
      <div className={image}>
        <img
          src={banner}
          srcSet={bannerSrcSet}
          alt={title}
          loading="lazy"
          width="302"
          height="227"
        />
      </div>

      <p className={about}>
        {tag}

        <span className={classnames('scr-lead', dateStyle)}>{date}</span>
      </p>

      <p className={titleStyle}>{title}</p>
    </Link>
  );
};

export default PastWebinar;
