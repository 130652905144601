import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

import {
  box,
  image,
  content,
  dateStyle,
  tagsStyle,
  titleStyle,
  subtitleStyle,
  inner,
  timeStyle,
} from './styles.module.scss';

const NextWebinar = ({ data }) => {
  const link = data.uri;
  const banner = data.webinar.webinarHero.webinarHeroBanner.sourceUrl;
  const tags = data.webinar.webinarHero.webinarHeroTags;
  const title = data.title;
  const subtitle = data.webinar.webinarHero.webinarHeroDescription;
  const time = data.webinar.webinarHero.webinarHeroTimeStart;
  const timeNote = data.webinar.webinarHero.webinarPreviewTimeNote;
  const date = data.webinar.webinarHero.webinarHeroDate;

  return (
    <Link to={link} className={box}>
      <div className={image}>
        <img src={banner} alt={title} width="337" height="264" />
      </div>

      <div className={inner}>
        <div className={content}>
          <p className={tagsStyle}>
            {tags.map((tag) => {
              return (
                <span key={tag.webinarHeroTagItem} className="scr-reg">
                  {tag.webinarHeroTagItem}
                </span>
              );
            })}
          </p>

          <p className={classnames('scr-h3', titleStyle)}>{title}</p>

          <p className={classnames('scr-lead', subtitleStyle)}>{subtitle}</p>

          <p className={classnames('scr-h5', timeStyle)}>
            {timeNote} {time}
          </p>
        </div>

        <div className={classnames('scr-h5', dateStyle)}>{date}</div>
      </div>
    </Link>
  );
};

export default NextWebinar;
