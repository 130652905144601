import ScreverBtqPreview from 'images/btq/screver-btq-preview.jpeg';
import ScreverOcxPreview from 'images/ocx/screver-ocx-preview.jpeg';

export const EVENTS = [
  {
    title: 'Screver Boutique Event: Let’s talk & Co-work',
    uri: '/events/screver-btq/',
    language: {
      code: 'EN',
      locale: 'en_US',
      name: 'English',
      slug: 'en',
    },
    webinarOnDemand: {
      webinarOnDemandHero: {
        webinarOnDemandHeroTags: [
          {
            webinarOnDemandHeroTagItem: 'Event',
          },
        ],
        webinarOnDemandHeroDate: 'June 21-23, 2023',
        webinarOnDemandHeroBanner: {
          sourceUrl: ScreverBtqPreview,
        },
      },
    },
  },
  {
    title: 'Unlocking Revenue: The Power of Everyday Events',
    uri: '/events/screver-ocx/',
    language: {
      code: 'EN',
      locale: 'en_US',
      name: 'English',
      slug: 'en',
    },
    webinarOnDemand: {
      webinarOnDemandHero: {
        webinarOnDemandHeroTags: [
          {
            webinarOnDemandHeroTagItem: 'Webinar',
          },
        ],
        webinarOnDemandHeroDate: 'March 9, 2023',
        webinarOnDemandHeroBanner: {
          sourceUrl: ScreverOcxPreview,
        },
      },
    },
  },
];
